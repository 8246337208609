@import "@/styles/base-imports";

.container {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;

  @include tablet {
    flex-direction: row;
    align-items: center;
  }
}

.search {
  display: inline-block;
  position: relative;
  font-size: 1.25rem;
  background: #fff;
  border-radius: 4px;
  outline: 1px solid rgba(112, 112, 112, 0.25);

  &>* {
    font-size: inherit;
  }
}

.searchIcon svg,
.clearIcon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.searchInput {
  padding: 0.25rem 1.75rem 0.25rem 1.75rem;
  width: 100%;
  border: none;
  background: none;
  font-size: inherit;
}

.searchIcon svg {
  left: 0.25rem;
}

.clearIcon svg {
  right: 0.25rem;
  cursor: pointer;
}

.button {
  padding-block: 1.5rem;
  padding-inline: 2rem;
  color: $offwhite;
  text-transform: uppercase;
  border-radius: 0 8px 8px 0;
  transition: background-color 0.3s ease;

  &.buttonDisabled {
    background-color: #a2a2a2;
  }

  &.buttonEnabled {
    background-color: $primary;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.input {
  border-radius: 8px 0 0 8px;
  padding-block: 1.5rem;
}