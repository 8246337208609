@import '@/styles/base-imports';

.container {
    --borderWidth: 4px;
    color: $secondary;
    border-bottom: var(--borderWidth) solid $secondary;
    padding-bottom: .5rem;
    position: relative;
    white-space: nowrap;
    font-style: italic;
    font-family: $family-plex-serif;
    font-weight: 700;

    &::after {
        content: "";
        border-radius: 100%;
        background: #fff;
        border: var(--borderWidth) solid $secondary;
        width: calc(var(--borderWidth) * 4);
        height: calc(var(--borderWidth) * 4);
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(75%, calc(75% - var(--borderWidth)/2));
    }
}