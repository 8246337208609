@import "@/styles/base-imports";

.bg {
    background-color: darken($primary, 0%);
    padding: 4em 2em;
    color: white;
}

.logo {
    border: 4px solid $secondary;
    border-right: 0;
    padding: 2em 1em 2em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    // Using flex instead of grid for better control with the smaller logo
    display: flex;
    justify-content: center;

    // This will help with logo sizing while maintaining borders
    &>div {
        max-width: 80%; // Constrains the logo container
        display: flex;
        align-items: center;
        justify-content: center;

        @include desktop {
            justify-content: flex-end; // Push logo to right on desktop
        }
    }

    @include touch {
        border-bottom: 0;
    }

    @media print {
        border: none;
    }
}

.right {
    border-bottom: 4px solid $secondary;
    display: flex;
    flex-direction: column;
    justify-content: center; // Vertically center content

    @include touch {
        padding: 2.5em 2.25em 2.5em 2.25em;
        border-left: 4px solid $secondary;
    }

    @include desktop {
        padding: 2.5em 6em 2.5em 6em;
    }

    @media print {
        border: none;
    }
}

.text {
    margin: 0; // Reset margin to ensure proper centering
}