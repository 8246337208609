@import "@/styles/base-imports";

.container {
  position: fixed;
  z-index: 20;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0px 3px 8px rgb(98 98 98 / 16%);
  padding-inline: 3rem;

  background: #fff;
  transition: max-width 0.5s ease-out;
  max-width: 100%;
  overflow: hidden;
  overflow-y: auto;

  @include tablet {
    width: 75%;
  }

  @include widescreen {
    width: 50%;
    padding-inline: 4rem;
  }

  &.close {
    max-width: 0%;
    padding: 0;
    position: relative;

    &>* {
      display: none;
    }
  }

  .closeHandle {
    margin-top: 2rem;
    text-align: right;
    padding-inline: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
}