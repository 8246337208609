@import '@/styles/base-imports';

.container {
  padding-bottom: 4rem;
}

.teaserContainer {
  display: grid;
  grid-template-columns: 1fr;
  margin-inline: 0;

  @include desktop {
      grid-template-columns: repeat(4, 1fr);
  }
}