@import "@/styles/base-imports";

.cardContainer {
    background: #fdfdfd 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgb(98 98 98 / 16%);
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: lighten($primary, 71%);
    }

    &:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    &:first-child,
    &:nth-child(2) {
        margin-top: -1.25rem;
    }

    > :nth-child(1),
    > :nth-child(2) {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
        }
    }

    > :nth-child(4) {
        justify-content: flex-end;
    }

    .titleRow {
        margin: 1rem;
        border-bottom: 1px solid lightgray;
    }


}

.card {
    font-family: $family-secondary;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 1rem;

    &.headerRow {
        background: $primary;
        box-shadow: none;
        border-radius: 0;
        color: $offwhite;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 0.5rem 1rem;

        @include tablet {
            -webkit-position: sticky;
            position: sticky;
            top: $fixed-nav-size;
        }

        &:hover {
            background: $primary;
        }
    }

    .descriptionColumn p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}