@import "@/styles/base-imports";

.container {
  display: flex;
  justify-content: space-between;

  padding-block: 2rem;
  padding-inline: 1rem;
  z-index: 9;

  &.bottom {
    margin-top: 3rem;
    margin-bottom: 0;
    margin-inline: -2rem;
    translate: 0 0.75rem;
    width: calc(100% + 4rem);
  }
}