@import '@/styles/base-imports';

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block: 2rem;

    @include tablet {
        flex-direction: row;
        align-items: center;
    }
}

.search {
    display: inline-block;
    position: relative;
    font-size: 1.25rem;
    background: #fff;
    border-radius: 4px;
    outline: 1px solid rgba(112, 112, 112, .25);

    &>* {
        font-size: inherit;
    }
}

.searchIcon svg,
.clearIcon svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.searchInput {
    padding: .25rem 1.75rem .25rem 1.75rem;
    width: 100%;
    border: none;
    background: none;
    font-size: inherit;
}

.searchIcon svg {
    left: .25rem;
}

.clearIcon svg {
    right: .25rem;
    cursor: pointer;
}