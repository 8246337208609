.container {
    // Your existing styles
    min-height: 100dvh;
}

// Custom scrollbar styles
.scrollableContent {
    // Firefox scrollbar
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) #d3d3d3; // Match bg-gray-100 color

    // Webkit scrollbars (Chrome, Safari, newer Edge)
    &::-webkit-scrollbar {
        width: 8px;
        background-color: #d3d3d3; // Match bg-gray-100 color
    }

    &::-webkit-scrollbar-track {
        background-color: #d3d3d3; // Match bg-gray-100 color
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        border: 2px solid #d3d3d3; // Creates padding effect with bg-gray-100 color

        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
}