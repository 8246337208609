@import "@/styles/base-imports";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  h2 {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 1.55rem;
    font-weight: normal;
    margin-top: 1rem;
  }
}

.metadataGrid {
  max-width: 75ch;
  display: grid;
  grid-template-columns: 1fr 6fr;
  outline: 2px solid $secondary;
  border-radius: 8px;
  padding: 1.5rem;
  gap: 0.75rem;

  .metadataTitle {
    place-self: center-start;
    grid-column: 1;
    font-weight: bold;
  }

  .metadataValue {
    place-self: center-start;
    grid-column: 2;
  }
}

.content {
  margin-top: 1.5rem;
  max-width: 75ch;
}

.footer {
  margin-top: 3rem;

  .cta {
    border-radius: 4px;
    text-transform: uppercase;
    padding-inline: 2rem;
    padding-block: 1.5rem;
    color: white;
    background-color: $primary;
  }
}